exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1c5bw{padding:20px;margin:10% auto 0;max-width:600px;border-radius:4px;text-align:center;box-sizing:border-box;background-color:#fff;box-shadow:0 18px 64px -38px rgba(14,14,20,.12),0 10px 24px -10px rgba(14,14,20,.14)}", ""]);

// exports
exports.locals = {
	"root": "_1c5bw"
};